import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/shell/layout"
import User from "../components/user/pageContents/User"
import PrivateRoute from "../components/user/privateRoute"

const App = () => (
  <Router>
    <PrivateRoute path="/app/user" component={User} />
  </Router>
)

export default App
