import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CourseCard from "./CourseCard";

import useMediaQuery from "@material-ui/core/useMediaQuery";

// TODO: Xem lại khi merge sang gatsby
const useStyles = makeStyles((theme) => ({
  title: {
    margin: "10px 0 30px 0",
  },
}));

function MyCourse(props) {
  const classes = useStyles();
  const tabletViewport = useMediaQuery("(min-width:768px)");

  return (
    <Container disableGutters={tabletViewport ? false : true}>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="primary"
      >
        Khóa học của tôi
      </Typography>
      <Box>
        {
          props.data && props.data.map((item, index) => {
            return <div key={index}>
              <CourseCard data={item} />
            </div>
          })
        }
      </Box>
    </Container>
  );
}

export default MyCourse;
