import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Container } from "@material-ui/core";
import { timestampToDate } from "../../../api/testingHistory.service"

const useStyles = makeStyles((theme) => ({
  MuiAccordion: {
    "&:before": {
      display: "none",
    },
    marginBottom: theme.spacing(3),
    borderRadius: "10px",
    border: "2px solid #76b7f1",
    boxShadow: "none",
  },
  MuiAccordionRounded: {
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "&:last-child": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  AccordionDetails: {
    paddingBottom: "0px",
    "&:last-child": {
      "& hr": {
        opacity: "0",
      },
    },
  },
  heading: {
    fontSize: "1.2rem",
    flexShrink: 0,
    fontWeight: "600",
    "@media screen and (min-width: 1024px)": {
      paddingLeft: "3rem",
    },
  },
  des1: {
    fontWeight: "400",
    color: "#a8a8a8",
    "@media screen and (min-width: 1024px)": {
      paddingLeft: "6rem",
    },
  },
  des2: {
    fontWeight: "750",
    color: "#68f786",
  },
  des3: {
    fontWeight: "750",
    color: "#fa9494",
  },
  des4: {
    fontWeight: "750",
    color: "#000000",
  },
}));

export default function TestCard({ data, courseName }) {
  const classes = useStyles();

  return <Accordion
    classes={{
      root: classes.MuiAccordion,
      rounded: classes.MuiAccordionRounded,
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography
        className={classes.heading}
        style={{ color: "#1E88E5" }}>
        {courseName}-Quiz
      </Typography>
    </AccordionSummary>
    {
      data && data.map((testingRecord, index) => {
        return <AccordionDetails key={index} className={classes.AccordionDetails}>
          <Container>
            <Typography>
              <span className={classes.des1}>Thời gian làm bài:</span>
              <span className={classes.des2}> {timestampToDate(testingRecord.date)}</span>
            </Typography>
            <Typography>
              <span className={classes.des1}> Tổng điểm: </span>
              <span className={classes.des3}>{testingRecord.point}/{testingRecord.total}</span>
            </Typography>
            <Typography>
              <span className={classes.des1}>Thời gian hoàn thành:</span>
              <span className={classes.des4}> {testingRecord.time}</span>
            </Typography>
            <hr width="75%" color="gray" />
          </Container>
        </AccordionDetails>
      })
    }
  </Accordion>
}