import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import Image from "../../common/Image";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    marginBottom: theme.spacing(3),
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "120%",
    },
    boxShadow: "none",
    border: "2px solid #76b7f1",
    borderRadius: "10px",
  },
  left: {
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    display: "block",
    maxHeight: "65px",
    width: "auto",
    height: "auto",
    padding: theme.spacing(1.2),
    [theme.breakpoints.up("sm")]: {
      maxHeight: "95px",
      padding: theme.spacing(2.2),
    },
  },
  right: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(1.2, 0),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(1.2),
  },
  cardTitle: {
    textTransform: "uppercase",
  },
  rating: {
    fontSize: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
  rootLinearProgress: {
    height: "6px",
    backgroundColor: "##9fd9b6",
    "@media screen and (min-width: 768px)": {
      height: "8px",
    },
    "@media screen and (min-width: 1024px)": {
      height: "10px",
    },
  },
  barColorPrimary: {
    backgroundColor: "#08c50b",
  },
}));

const CourseCard = ({ data }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.left}>
        <Image className={classes.img} src={data.imageUrl} />
      </div>

      <div className={classes.right}>
        <div className={classes.title}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.cardTitle}
          >
            {data.name}
          </Typography>
          <Rating
            defaultValue={data.rating}
            precision={0.5}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            className={classes.rating}
            readOnly
          />
        </div>

        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={data.progress}
              classes={{
                root: classes.rootLinearProgress,
                barColorPrimary: classes.barColorPrimary,
              }}
            />
          </Box>
          <Box minWidth={70}>
            <Typography variant="body2" color="primary">{data.percentage}</Typography>
          </Box>
        </Box>
      </div>
    </Card>
  );
};
export default CourseCard;
