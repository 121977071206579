import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

TextInput.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  label: PropTypes.string,
  disabled: PropTypes.bool,
};

function TextInput(props) {
  const { form, name, label, type, disabled } = props;
  const { errors } = form;
  const hasError = errors[name];
  return (
    <Controller
      name={name}
      label={label}
      type={type}
      control={form.control}
      disabled={disabled}
      as={TextField}
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      error={!!hasError}
      helperText={errors[name]?.message}
    />
  );
}

export default TextInput;
