import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../utils/auth"
import getFirebase from "../../utils/firebase"
import Layout from "../shell/layout"

const firebase = getFirebase()
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [firebaseCreated, setFirebaseCreated] = useState(false)

  useEffect(() => {
    firebase &&
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          return navigate(`/home`, { replace: true })
        }

        setFirebaseCreated(true)
      })
  }, [firebase])
  return (
    firebaseCreated && (
      <Layout>
        <Component {...rest} />
      </Layout>
    )
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
