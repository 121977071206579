import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import EditInformation from "./EditInformation";
import Avatar from "@material-ui/core/Avatar";
// import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  info: {
    padding: 0,
  },

  info_title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  userAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "0 auto",
    marginBottom: "20px",
  },

  info_name: {
    marginBottom: theme.spacing(4),
  },

  list_item: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "300px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
  },

  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },

  item_title: {
    width: "35%",
    flexShrink: "0",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },

  item_content: {
    flexGrow: "1",
    wordBreak: "break-all",
    fontWeight: "600",
  },
}));

function UserInformation({ data, needsReloading }) {
  const classes = useStyles();
  const [isEditting, setIsEditting] = useState(false);
  const handleEditIconClick = () => {
    setIsEditting(true);
  };
  const handleAfterSavedData = (result) => {
    setIsEditting(false);
    needsReloading(true);
  };
  const handleCancelEdittingData = () => {
    setIsEditting(false);
  };

  return (
    <React.Fragment>
      {!isEditting ? (
        <Container classes={{ root: classes.info }}>
          <div className={classes.info_title}>
            <Typography
              component="h2"
              variant="h5"
              align="center"
              color="primary"
            >
              Thông tin tài khoản
            </Typography>
            <IconButton onClick={handleEditIconClick} color="primary">
              <EditIcon />
            </IconButton>
          </div>

          <Avatar
            alt={data.displayName}
            src={data.photoURL}
            className={classes.userAvatar}
          />

          <Typography
            component="h4"
            variant="h5"
            align="center"
            color="primary"
            className={classes.info_name}
          >
            {data.displayName}
          </Typography>
          <div className={classes.list_item}>
            <div className={classes.item}>
              <Typography variant="body1" className={classes.item_title}>
                Ngày sinh:
              </Typography>
              <Typography variant="body1" className={classes.item_content}>
                {data.birthdate &&
                  `${data.birthdate.getDate() < 10
                    ? "0" + data.birthdate.getDate()
                    : data.birthdate.getDate()
                  }/${data.birthdate.getMonth() < 9
                    ? "0" + (data.birthdate.getMonth() + 1)
                    : data.birthdate.getMonth() + 1
                  }/${data.birthdate.getFullYear()}`}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="body1" className={classes.item_title}>
                Số điện thoại:
              </Typography>
              <Typography variant="body1" className={classes.item_content}>
                {data.phoneNumber}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="body1" className={classes.item_title}>
                Email:
              </Typography>
              <Typography variant="body1" className={classes.item_content}>
                {data.email}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="body1" className={classes.item_title}>
                Giới tính:
              </Typography>
              <Typography variant="body1" className={classes.item_content}>
                {data.sex}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="body1" className={classes.item_title}>
                Địa chỉ:
              </Typography>
              <Typography variant="body1" className={classes.item_content}>
                {data.address}
              </Typography>
            </div>
          </div>
        </Container>
      ) : (
        <EditInformation
          onAfterSavedData={handleAfterSavedData}
          onCancelEdittingData={handleCancelEdittingData}
          data={data}
        />
      )}
    </React.Fragment>
  );
}

export default UserInformation;
