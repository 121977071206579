import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";

GenderInput.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  gender_label: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    paddingTop: theme.spacing(2),
  },
  radioGender: {
    flexDirection: "row",
  },
}));

function GenderInput(props) {
  const classes = useStyles();
  const { form, name } = props;
  const { errors } = form;
  const hasError = errors[name];
  return (
    <React.Fragment>
      <FormLabel component="legend" className={classes.gender_label}>
        Giới tính
      </FormLabel>
      <Controller
        name={name}
        control={form.control}
        error={!!hasError}
        helperText={errors[name]?.message}
        as={
          <RadioGroup
            // aria-label="gender"
            // defaultValue={""}
            className={classes.radioGender}
          >
            <FormControlLabel value="Nữ" control={<Radio />} label="Nữ" />
            <FormControlLabel value="Nam" control={<Radio />} label="Nam" />
          </RadioGroup>
        }
      />
    </React.Fragment>
  );
}

export default GenderInput;
