import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import TestCard from "./TestCard"

const useStyles = makeStyles((theme) => ({
  root: {
    "@media screen and (max-width: 767px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  title: {
    margin: "10px 0 30px 0",
  },

}));

function TestHistory({ data }) {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="primary">
        Lịch sử kiểm tra
      </Typography>
      {data && data.map(item => <TestCard key={item.id} data={item.testingRecords} courseName={item.courseName} />)}
    </Container>
  );
}

export default TestHistory;
