import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BirthdayInput from "../../common/form-controls/BirthdayInput";
import GenderInput from "../../common/form-controls/GenderInput";
import TextInput from "../../common/form-controls/TextInput";
import { addOrUpdateUserInfo } from "../../../api/user.service";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  userAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "0 auto",
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 3% 0",
  },
  row_gender: {
    marginRight: 0,
    marginBottom: "3%",
  },
  gender_label: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    paddingTop: theme.spacing(2),
  },
  radioGender: {
    flexDirection: "row",
  },
  row_button: {
    textAlign: "center",
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: theme.spacing(15),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(30),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const EditInformation = ({ data, onCancelEdittingData, onAfterSavedData }) => {
  const classes = useStyles();
  const now = new Date();
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    displayName: yup.string().required("Hãy nhập tên của bạn"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Số điện thoại không đúng")
      .min(10, "Số điện thoại không đúng")
      .max(13, "Số điện thoại không đúng")
      .required("Hãy nhập số điện thoại"),
    birthdate: yup
      .date()
      .typeError("Hãy nhập ngày tháng năm sinh của bạn")
      .max(
        today,
        "Bạn không thể nhập ngày sinh vào hôm nay hoặc tương lai"
      ),
  });

  const form = useForm({
    defaultValues: {
      displayName: data.displayName,
      photoURL: data.photoURL,
      birthdate: data.birthdate || null,
      email: data.email,
      phoneNumber: data.phoneNumber || "",
      sex: data.sex || "",
      address: data.address || "",
    },
    resolver: yupResolver(schema),
  });

  const handleCancel = (event) => {
    onCancelEdittingData();
    event.preventDefault();
  };

  const onSubmit = (values) => {
    addOrUpdateUserInfo(values).then(() => {
      onAfterSavedData(values);
    });
  };

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Avatar
            alt={data.displayName}
            src={data.photoURL}
            className={classes.userAvatar}
          />

          <div className={classes.row}>
            <TextInput
              name="displayName"
              label="Họ tên"
              type="text"
              form={form}
            />
          </div>

          <div className={classes.row}>
            <BirthdayInput name="birthdate" label="Ngày sinh" form={form} />
          </div>

          <div className={classes.row}>
            <TextInput
              name="email"
              label="Email"
              type="email"
              form={form}
              disabled
            />
          </div>

          <div className={classes.row}>
            <TextInput
              name="phoneNumber"
              label="Số điện thoại"
              type="text"
              form={form}
            />
          </div>

          <div className={classes.row} className={classes.row_gender}>
            <GenderInput name="sex" label="Giới tính" form={form} />
          </div>

          <div className={classes.row}>
            <TextInput name="address" label="Địa chỉ" type="text" form={form} />
          </div>

          <div className={classes.row_button}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              className={classes.button}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Lưu
            </Button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default EditInformation;
