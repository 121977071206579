import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";

import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Box from "@material-ui/core/Box";

import MyCourse from "../sections/MyCourse";
import TestHistory from "../sections/TestHistory";
import UserInformation from "../sections/UserInformation";
import LikedBlog from "../sections/LikedBlog";
import { loadingUserInfo } from "../../../api/user.service";
import { loadLearningProgress } from "../../../api/learningProgress.service";
import { loadTestingRecords } from "../../../api/testingHistory.service";
import { getLikedBlogByUser } from "../../../api/blog.service";

// TODO: tạo component Tab và dùng hàm map
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "40px",
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "233px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
  },

  Paper: {
    borderRadius: "15px",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  tabs: {
    position: "relative",
    top: "1px",
    [theme.breakpoints.up("md")]: {
      top: "0",
      right: "-1px",
      minWidth: "225px",
    },
  },

  tabPanel: {
    // padding: theme.spacing(3),
    borderTop: "1px solid #808080",
    [theme.breakpoints.up("md")]: {
      flexGrow: "1",
      borderTop: "none",
      borderLeft: "1px solid #808080",
      padding: theme.spacing(3),
    },
  },

  tabRoot: {
    textTransform: "capitalize",
    textAlign: "left",
    flexShrink: 1,
  },

  wrapped: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
  tab_name: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    [theme.breakpoints.up("md")]: {
      marginRight: "10px",
      verticalAlign: "middle",
    },
  },
  tab_title: {
    fontSize: "18px",
    fontWeight: "500",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const User = () => {
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState();
  const [learnedCourses, setLearnedCourses] = useState();
  const [testingRecords, setTestingRecords] = useState();
  const [likedBlog, setLikedBlog] = useState();
  const [reloadingUserInfo, setReloadingUserInfo] = useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);
  const desktopViewport = useMediaQuery("(min-width:1024px)");

  const handleTabClicked = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    // Loading basic user information
    loadingUserInfo().then((result) => {
      setUserInfo(result);
      setReloadingUserInfo(false);
    });
  }, [reloadingUserInfo]);

  useEffect(() => {
    // load learning progress
    loadLearningProgress().then((res) => {
      setLearnedCourses(res);
    });

    // load testing history
    loadTestingRecords().then((res) => {
      setTestingRecords(res);
    });

    //load likedBlog
    getLikedBlogByUser().then((res) => {
      setLikedBlog(res);
    });
  }, []);

  return (
    <Container className={classes.container}>
      <Paper
        elevation={3}
        classes={{
          root: classes.Paper,
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabClicked}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabs}
          variant="fullWidth"
          orientation={desktopViewport ? "vertical" : "horizontal"}
        >
          <Tab
            {...a11yProps(0)}
            label={
              desktopViewport ? (
                <div className={classes.tab_name}>
                  <PersonPinIcon className={classes.icon} />
                  <Typography className={classes.tab_title}>
                    Thông tin tài khoản
                  </Typography>
                </div>
              ) : (
                <PersonPinIcon />
              )
            }
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapped,
            }}
          />
          <Tab
            {...a11yProps(1)}
            label={
              desktopViewport ? (
                <div className={classes.tab_name}>
                  <ListAltIcon className={classes.icon} />
                  <Typography className={classes.tab_title}>
                    Khóa học
                  </Typography>
                </div>
              ) : (
                <ListAltIcon />
              )
            }
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapped,
            }}
          />
          <Tab
            {...a11yProps(2)}
            label={
              desktopViewport ? (
                <div className={classes.tab_name}>
                  <PlaylistAddCheckIcon className={classes.icon} />
                  <Typography className={classes.tab_title}>
                    Lịch sử test
                  </Typography>
                </div>
              ) : (
                <PlaylistAddCheckIcon />
              )
            }
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapped,
            }}
          />
          <Tab
            {...a11yProps(3)}
            label={
              desktopViewport ? (
                <div className={classes.tab_name}>
                  <ThumbUpIcon className={classes.icon} />
                  <Typography className={classes.tab_title}>
                    Bài viết yêu thích
                  </Typography>
                </div>
              ) : (
                <ThumbUpIcon />
              )
            }
            classes={{
              root: classes.tabRoot,
              wrapper: classes.wrapped,
            }}
          />
        </Tabs>

        <TabPanel value={tabIndex} index={0} className={classes.tabPanel}>
          {userInfo && (
            <UserInformation
              needsReloading={setReloadingUserInfo}
              data={userInfo}
            />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={1} className={classes.tabPanel}>
          {learnedCourses && <MyCourse data={learnedCourses} />}
        </TabPanel>
        <TabPanel value={tabIndex} index={2} className={classes.tabPanel}>
          {testingRecords && <TestHistory data={testingRecords} />}
        </TabPanel>
        <TabPanel value={tabIndex} index={3} className={classes.tabPanel}>
          {likedBlog && <LikedBlog data={likedBlog} />}
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default User;
