import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

PhoneInput.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

function PhoneInput(props) {
  const { form, name, label } = props;
  const { errors } = form;
  const hasError = errors[name];
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={form.control}
        render={({ ref, ...rest }) => (
          <KeyboardDatePicker
            fullWidth
            label={label}
            variant="inline"
            margin="normal"
            id="date-picker-inline"
            format="dd/MM/yyyy"
            InputLabelProps={{
              shrink: true,
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            {...rest}
            error={!!hasError}
            helperText={errors[name]?.message}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
}

export default PhoneInput;
